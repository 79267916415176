

import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import RudderStackHandler from "../../mixins/RudderStackHandler";
import config from "../../utils/config";

export default {

  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin, RudderStackHandler],
  props: {
    isProductDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    slickOptions: {
      type: Object,
      default: {}
    },
    notModifiedClass: {
      type: Boolean,
      default: true
    },
    isLarger: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      num: 4,
      swiping: false,
      widthScreen: '',
      currentSlide: 0,
      centerIndex: null
    }
  },
  mounted() {
    this.getNum()
    if (this.isLarger) this.handleAfterChange(0)
  },
  methods: {
    handleAfterChange(currentSlide) {
      const totalItems = this.data.length;

      // Calculate the index of the center item
      let centerIndex = currentSlide + Math.floor(this.num / 2) + 1;
      // if (centerIndex >= totalItems) {
      //   centerIndex = centerIndex % totalItems;
      // }
      if (centerIndex >= this.data.length) {
        this.centerIndex = 0
      } else {
        this.centerIndex = centerIndex;
      }
    },
    setCarouselSwiping(state) {
      this.swiping = state;
    },
    getNum() {
      if (this.slickOptions?.responsive) {
        for (const index in this.slickOptions.responsive) {
          if (this.$vssWidth >= this.slickOptions.responsive[index].breakpoint) {
            this.num = this.slickOptions.responsive[index].settings.slidesToShow
            break
          }
        }
      }
    },
    afterChange(currentSlide) {
      if (this.isLarger) this.handleAfterChange(currentSlide)
      if (this.isProductDetail) {
        const ids = this.getListIdProductVisiable(currentSlide, this.num)
        if (!ids.includes('undefined')) {
          this.rudderTrack(config.newEventName.related_product_impresion, {
            page_url: window.location.href,
            product_list: ids
          })
        }
      }
    },
    getListIdProductVisiable(index, num) {
      const newArr = this.data.slice(index, index + num)
      const arrId = []
      for (const item of newArr) {
        if (item.id) arrId.push(item.id)
        else arrId.push(item._id)
      }
      return arrId.join(',')
    }
  },
}
