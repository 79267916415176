
import SlickCarousel from "@/components/Elements/SlickCarousel";
import PrimaryBannerItem from "@/components/Banner/PrimaryBanner/PrimaryBannerItem";
import SecondaryBannerItem from "@/components/Banner/PrimaryBanner/SecondaryBannerItem";

export default {
  name: "BannerList",
  components: {SecondaryBannerItem, PrimaryBannerItem, SlickCarousel},
  props: ["banners", "slickOptions", "isPrimaryBanner", "methodModal", "haveModal"],

}
