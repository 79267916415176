
import UrlImageHandler from "@/mixins/UrlImageHandler";
import ResizeHandler from '@/mixins/ResizeHandler'

export default {
  name: "SecondaryBannerItem",
  props: ["item", "methodModal", "haveModal"],
  mixins: [UrlImageHandler, ResizeHandler],
  data() {
    return {
      imgUrl: '',
      imgWidth: 0
    }
  },
  watch: {
    windowWidth: {
      handler: function(breakpoint) {
        if(breakpoint < 920) {
          this.imgUrl = this.item.imageMobile
          this.imgWidth = 920
        } else {
          this.imgUrl = this.item.image_url
          this.imgWidth = 1920
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    redirect(item) {
      if (item.link_url) {
        window.location.href = item.link_url
      }
    }
  }
}
