
import UrlImageHandler from "@/mixins/UrlImageHandler";
import ResizeHandler from '@/mixins/ResizeHandler'

export default {
  name: "PrimaryBannerItem",
  props: ["item"],
  mixins: [UrlImageHandler, ResizeHandler],
  data() {
    return {
      optionImg: {
        w: 1920
      },
      timeout: null,
      bannerItem: {}
    }
  },
  watch: {
    item: {
      handler: function(item) {
        this.bannerItem = {
          ...item,
          loading: true
        }
      },
      deep: true,
      immediate: true
    },
    windowWidth: {
      handler: function(breakpoint) {
        clearTimeout(this.timeout)
        if(breakpoint && breakpoint < 640) {
          this.optionImg.w = 640
          this.imgUrl = this.item.imageMobile
        } else if(breakpoint < 1024) {
          this.imgUrl = this.item.imageMobile
          this.optionImg.w = 1024
        } else if(breakpoint < 1280) {
          this.imgUrl = this.item.image_url
          this.optionImg.w = 1280
        } else {
          this.imgUrl = this.item.image_url
          this.optionImg.w = 1920
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onLoadedImg(item) {
      setTimeout(() => {
        item.loading = false
      }, 100)
    },
    redirect(item) {
      if (item.link_url) {
        window.location.href = item.link_url
      }
    }
  }
}
