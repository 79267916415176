
import config from '@/utils/config'
import LazyHydrate from 'vue-lazy-hydration';
import SlickCarousel from '@/components/Elements/SlickCarousel'
import UrlImageHandler from '@/mixins/UrlImageHandler'
import BannerList from "@/components/Banner/PrimaryBanner/PrimaryBannerList";
import { ACTION_GET_BANNER_CUSTOM } from '@/utils/store.action'

export default {
  mixins: [UrlImageHandler],
  components: {
    BannerList,
    SlickCarousel,
    LazyHydrate
  },
  props: ['data'],
  data() {
    return {
      config,
      isViewMore: false,
      loading: false,
      banners: [],
      slickOptions: {
        arrows: false,
        lazyLoad: "ondemand",
        dots: true,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        dotsClass: 'banner-home-dots'
      }
    }
  },
  async created() {
    await this.getBanners()
  },
  methods: {
    async getBanners() {
      try {
        this.loading = true
        this.banners = await this.$store.dispatch(ACTION_GET_BANNER_CUSTOM, {screen: config.screenBanner.HOME_PAGE, position: 1})
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    toggleViewMore() {
      this.isViewMore = !this.isViewMore
    }
  }
}
